import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    async saveProposal({ commit }, proposal) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .put(`/project-line-proposal/${proposal.id}`, proposal)
        .catch((err) => {
          console.error("Error from setProjectLineProposal: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async seenProposal({ dispatch }, id) {
      await axios
        .get(`/proposal-notification-seen/${id}`)
        .then((res) => {
          if (res.data.status === "Success") {
            dispatch("events/getUsersNotifications", null, { root: true });
          }
        })
        .catch((err) => {
          console.error("Error from setProjectLineProposal: ", err);
        });
    },
  },
};
