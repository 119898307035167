<template>
  <div class="menu" :class="{ menu_full: !isMenuShown }">
    <div class="menu-heading">
      <div
        class="menu-icon-wrapper pointer"
        @click="isMenuShown = !isMenuShown"
      >
        <IconWallet icon-color="#17a2b8" />
      </div>
      <p v-if="isMenuShown" class="small-text">{{ todayDate }}</p>
    </div>
    <ul>
      <li v-for="item in menuListAdmin" :key="item.name">
        <router-link :to="{ name: `${item.link}` }" class="pointer">
          <div class="menu-icon-wrapper">
            <component
              :is="item.icon"
              :active="item.listen ?? false"
              icon-color="#828282"
              width="20"
              height="24"
            />
          </div>

          <div v-if="isMenuShown" class="menu-text">
            {{ item.name }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import AdminSign from "./header/AdminSign";
import convertDateFormat from "@/modules/helpers/convertDateFormat.js";
import IconInfo from "@/modules/components/icons/IconInfo";
import IconChecked2 from "@/modules/components/icons/IconChecked2";
import IconWallet from "@/modules/components/icons/IconWallet";
import IconDashboard from "@/modules/components/icons/IconDashboard";
import IconQuestion from "@/modules/components/icons/IconQuestion";
import IconFlame from "@/modules/components/icons/IconFlame";
import IconFolder from "@/modules/components/icons/IconFolder";
import IconFolder2 from "@/modules/components/icons/IconFolder2";
import IconPoint from "@/modules/components/icons/IconPoint";
import IconPoint2 from "@/modules/components/icons/IconPoint2";
import IconMessenger from "@/modules/components/icons/IconMessenger";
import IconSettings from "@/modules/components/icons/IconSettings";
import {useStore} from "vuex";

export default {
  components: {
    IconInfo,
    IconChecked2,
    IconWallet,
    AdminSign,
    IconDashboard,
    IconQuestion,
    IconFlame,
    IconFolder,
    IconFolder2,
    IconPoint,
    IconPoint2,
    IconMessenger,
    IconSettings,
  },
  setup() {
    const store = useStore();

    const notificationsProjectDraftMessages = computed(() => store.getters["events/notificationsProjectDraftMessages"]);
    const notificationsProjectFeasibilityMessages = computed(() => store.getters["events/notificationsProjectFeasibilityMessages"]);
    const notificationsProjectProjectMessages = computed(() => store.getters["events/notificationsProjectProjectMessages"]);
    const notificationsProjectReopenMessages = computed(() => store.getters["events/notificationsProjectReopenMessages"]);
    const notificationsAdminPSMessages = computed(() => store.getters["events/notificationsAdminPSMessages"]);
    store.dispatch("events/getUsersNotifications");

    const menuListAdmin = ref([
      {
        name: "Dashboard",
        link: "Dashboard",
        icon: "IconDashboard",
      },
      {
        name: "Clients Requests",
        link: "ProjectList",
        icon: "IconQuestion",
        listen: notificationsProjectDraftMessages,
      },
      {
        name: "Feasibility Requests",
        link: "FeasibilityList",
        icon: "IconInfo",
        listen: notificationsProjectFeasibilityMessages,
      },
      {
        name: "Active Projects",
        link: "ActiveList",
        icon: "IconFlame",
        listen: notificationsProjectProjectMessages,
      },
      {
        name: "Archived Projects",
        link: "ArchiveList",
        icon: "IconFolder",
        listen: notificationsProjectReopenMessages,
      },
      {
        name: "Projects  Tracking",
        link: "ProjectTracking",
        icon: "IconPoint",
      },
      {
        name: "Track Shipping",
        link: "TrackShipping",
        icon: "IconPoint2",
      },
      {
        name: "Quality Control",
        link: "QualityControl",
        icon: "IconChecked2",
      },
      {
        name: "Tss Messenger",
        link: "Messenger",
        icon: "IconMessenger",
        listen: notificationsAdminPSMessages,
      },
      {
        name: "Settings",
        link: "Settings",
        icon: "IconSettings",
      },
    ]);

    const isMenuShown = ref(true);
    const todayDate = convertDateFormat.formatCurrentDate();

    return { menuListAdmin, isMenuShown, todayDate };
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  width: 246px;
  font-size: 12px;
  font-family: "SFProDisplay-Regular";
  text-align: center;
  color: var(--col-secondary);
}

.menu {
  width: 246px;
  // margin-top: 5px;
  background: #ffffff;

  &_full {
    width: auto;
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 18px;
    // padding: 0 5px 0;
    border-bottom: 1px solid var(--col-primary-light);
  }

  &-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // width: 24px;
    // height: 20px;
  }

  &-text {
    color: grey;
    font-family: "OpenSans-Semibold";
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--col-primary);
  }
}
li {
  border-bottom: 1px solid var(--col-primary-light);
  transition: 0.17s all;
}
li > a {
  display: flex;
  justify-content: space-between;
  padding: 16px 5px 12px 18px;

  &:hover,
  &.router-link-active,
  &:focus {
    background-color: var(--col-bg-primary);
  }
}
</style>
