import axios from "axios";
import Cookies from "@/modules/helpers/cookies";

if (process.env.VUE_APP_AXIOS_BASE_URL) {
  axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;
} else {
  axios.defaults.baseURL = "https://api.cureline-crm.cureline.com/api";
}
axios.defaults.withCredentials = true;

let aToken = localStorage.getItem("authToken");
if (aToken !== "") {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("authToken")}`;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      window.localStorage.clear();
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
      Cookies.setCookie(
        "cureline_notification",
        JSON.stringify([
          {
            title: error.response.statusText,
            text: error.response.data.message,
            type: "error",
          },
        ]),
        1
      );
    } else {
      Cookies.setCookie(
        "cureline_notification",
        JSON.stringify([
          {
            title: error.response.statusText,
            text: error.response.data.message,
            type: "error",
          },
        ]),
        1
      );
    }
    return error;
  }
);

export default axios;
